import React, { Fragment } from 'react';
import { useParams } from "react-router-dom";
import projectsData from '../data/projects';
import underlineName from "../components/Projects/underlineName";
import Main from '../layouts/Main';
import { Link } from 'react-router-dom';

// import projects from

const ProjectDetails = () => {
  const { link } = useParams();
  const project = projectsData.find((p) => p.link === `/research/${link}`);
  return (
    <Main
      title={project.paper_title}
      description={`Details on project.${project.title}`}
    >
    {/* <div className="cell-container"> */}
      <article className="post">
        <header>
          <div className="title">
            <h2><a href={project.doi}>{project.paper_title}</a></h2>
            {/* {project.subtitle} */}
            {/* <time className="published">{dayjs(project.date).format('MMMM, YYYY')}</time> */}
            <p>{project.subtitle}</p>
          </div>
        </header>
        <img src={`${process.env.PUBLIC_URL}${project.image}`} width="100%" alt={project.title} />
        <div className="description">
          {'summary' in project ? <p className="desc_desc"><b>One-sentence summary: </b>{project.summary}</p> : ''}
          <p className="desc_desc"><b>In topics: </b>
            {project.topics.map((topic) => (
              <span> <a href={`/research/topic/${topic.replace(/\s+/g, '')}`} className="button">{topic}</a> </span>
            ))}
          </p>
          <p><b>Abstract:</b><br />
          {project.abstract}</p>
          {'keywords' in project ? <p className="desc_desc"><b>Keywords: </b>{project.keywords.join(', ')}</p> : ''}
        </div>
        {
          'video' in project ? 
          <p><div><b>Video:</b>
          <div className="video-container">
            <iframe src={`https://www.youtube.com/embed/${project.video}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div></div></p> : 
          ''
        }
        <div className="description">
          <p><b>Reference:</b><br />{underlineName(project.authors)}.&nbsp;{project.year}.&nbsp;{project.paper_title}.&nbsp;In <i>{project.journal}</i>.&nbsp;{project.publisher}.&nbsp;<a target='blank' href={project.doi}>{project.doi}</a></p>
        </div>
        {
          'media_coverage' in project ?
          <div className='media_coverage'>
            <p><b>Media coverage:</b>&nbsp;&nbsp;&nbsp;&nbsp;
              {project.media_coverage.map((m, index) => (
                <Fragment key={index}>
                  <Link target='blank' to={m.url}>{m.source}</Link>&nbsp;&nbsp;&nbsp;&nbsp;
                </Fragment>
              ))}
            </p>
          </div> :
          <></>
        }
        <div className="description">
          <p></p>
          <a target='blank' href={'pdf' in project ? project.pdf : project.doi} className="button">Download paper</a>
          <p></p>
        </div>
      </article>
    {/* </div> */}
    </Main>
  );
};

// ProjectDetails.propTypes = {
//   data: PropTypes.shape({
//     title: PropTypes.string.isRequired,
//     link: PropTypes.string,
//     image: PropTypes.string.isRequired,
//     date: PropTypes.string.isRequired,
//     desc: PropTypes.string.isRequired,
//   }).isRequired,
// };

export default ProjectDetails;
