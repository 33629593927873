// TODO Add a couple lines about each project
const talksData = [
  {
    title: 'Wireless Sensing Around the Body: Towards Tracking and Interpreting Body Postures in the Wild on Wearables',
    subtitle: 'May 2024 @ Tokyo, Japan',
    location: 'Tokyo, Japan',
    image: '/images/talks/utokyotalk.jpg',
    link: '/talks/utokyotalk',
    date: '2024-05-20',
    projects: ['EchoWrist', 'EchoSpeech', 'HPSpeech', 'EyeEcho', 'EarIO'],
    talk_desc:
      'My labmate Ke and I were invited to give a talk on our research at the University of Tokyo, hosted by Prof. Yoshihiro Kawahara.',

    year: '2023',
    abstract:
      'Despite the fast growth of AI, computers have limited ability to interpret human physical behaviors in everyday life. For example, '
      + 'computers still cannot yet accurately distinguish basic daily activities such as eating and drinking. One key challenge is the lack '
      + 'of high-quality behavior data in the wild, such as body postures on the hands, face, and limbs. Existing body posture sensing '
      + 'technologies encounter at least one of the following issues when used continuously in daily settings: 1) limited sensing area, '
      + '2) cumbersome form factor, 3) high energy consumption, and 4) low resolution. The inability to continuously track high-quality '
      + 'fine-grained body postures in daily settings has hindered computers’ ability to comprehensively and continuously understand complex '
      + 'human behaviors and interpret their intentions.\n'
      + 'The research in the SciFi lab aims to develop a low-power, privacy-sensitive, and intelligent sensing platform with minimally '
      + 'obtrusive wearables that can be deployed in everyday life to track and interpret a key set of fine-grained body postures. The key '
      + 'sensing principle behind these projects is that different body postures lead to unique patterns and formations of nearby skin or '
      + 'contours of the body, which can be captured by wearable wireless sensors to infer fine-grained body postures and activities. In this '
      + 'talk, we will present a series of novel sensing technologies from the SciFi lab, including an earable and a pair of glasses that can '
      + 'track facial expressions (IMWUT’22 & CHI’24, Ke Li), a pair of glasses that can continuously track gaze movements (MobiCom’24, Ke Li), '
      + 'a pair of glasses and a headphone that can continuously interpret speech from subtle skin deformations (CHI’23 & ISWC’23, Ruidong Zhang), '
      + 'and a wristband that can reconstruct hand postures and recognize hand-object interaction (CHI’24, Ruidong Zhang).',
  },
  {
    title: 'EchoWrist: Continuous Hand Pose Tracking and Hand-Object Interaction Recognition Using Low-Power Active Acoustic Sensing On a Wristband',
    subtitle: 'May 2024 @ Honolulu, HI, USA',
    location: 'Honolulu, HI, USA',
    image: '/images/talks/echowristtalk.jpg',
    link: '/talks/echowristtalk',
    date: '2024-05-15',
    projects: ['EchoWrist'],
    talk_desc:
      'I gave a talk on EchoWrist at CHI \'24 together with my co-author Chi-Jung Lee, where we performed a live demo to showcase EchoWrist\'s real-time performance.',

    year: '2024',
    abstract:
      'Our hands serve as a fundamental means of interaction with the world around us. Therefore, understanding hand poses and interaction contexts '
      + 'is critical for human-computer interaction (HCI). We present EchoWrist, a low-power wristband that continuously estimates 3D hand poses and '
      + 'recognizes hand-object interactions using active acoustic sensing. EchoWrist is equipped with two speakers emitting inaudible sound waves '
      + 'toward the hand. These sound waves interact with the hand and its surroundings through reflections and diffractions, carrying rich '
      + 'information about the hand’s shape and the objects it interacts with. The information captured by the two microphones goes through a '
      + 'deep learning inference system that recovers hand poses and identifies various everyday hand activities. Results from the two '
      + '12-participant user studies show that EchoWrist is effective and efficient at tracking 3D hand poses and recognizing hand-object '
      + 'interactions. Operating at 57.9 mW, EchoWrist can continuously reconstruct 20 3D hand joints with MJEDE of 4.81 mm and recognize '
      + '12 naturalistic hand-object interactions with 97.6% accuracy.',
  },
  {
    title: 'HPSpeech: Silent Speech Interface for Commodity Headphones',
    subtitle: 'October 2023 @ Cancun, Mexico',
    location: 'Cancun, Mexico',
    image: '/images/talks/hpspeechtalk.jpg',
    link: '/talks/hpspeechtalk',
    date: '2023-10-10',
    projects: ['HPSpeech'],
    talk_desc:
      'I gave a talk on HPSpeech at Ubicomp \'23, where I performed a live demo using HPSpeech to control a music player and play Tetris.',

    year: '2023',
    abstract:
      'We present HPSpeech, a silent speech interface for commodity headphones. HPSpeech utilizes the existing speakers '
      + 'of the headphones to emit inaudible acoustic signals. The movements of the temporomandibular joint (TMJ) during '
      + 'speech modify the reflection pattern of these signals, which are captured by a microphone positioned inside the '
      + 'headphones. To evaluate the performance of HPSpeech, we tested it on two headphones with a total of 18 participants. '
      + 'The results demonstrated that HPSpeech successfully recognized 8 popular silent speech commands for controlling the '
      + 'music player with an accuracy over 90%. While our tests use modified commodity hardware (both with and without active '
      + 'noise cancellation), our results show that sensing the movement of the TMJ could be as simple as a firmware update for '
      + 'ANC headsets which already include a microphone inside the hear cup. This leaves us to believe that this technique has '
      + 'great potential for rapid deployment in the near future. We further discuss the challenges that need to be addressed '
      + 'before deploying HPSpeech at scale.',
  },
  {
    title: 'EchoSpeech: Continuous Silent Speech Recognition on Minimally-obtrusive Eyewear Powered by Acoustic Sensing',
    subtitle: 'August 2023 @ Google (remote)',
    location: 'Google (remote)',
    image: '/images/projects/echospeech.jpg',
    link: '/talks/echospeechgoogletalk',
    date: '2023-08-09',
    projects: ['EchoSpeech'],
    talk_desc:
      'I was invited to give a talk on EchoSpeech at Google remotely.',

    year: '2023',
    abstract:
      'We present EchoSpeech, a minimally-obtrusive silent speech interface (SSI) powered by low-power'
      + ' active acoustic sensing. EchoSpeech uses speakers and microphones mounted on a glass-frame and'
      + ' emits inaudible sound waves towards the skin. By analyzing echos from multiple paths, EchoSpeech'
      + ' captures subtle skin deformations caused by silent utterances and uses them to infer silent speech.'
      + ' With a user study of 12 participants, we demonstrate that EchoSpeech can recognize 31 isolated'
      + ' commands and 3-6 figure connected digits with 4.5% (std 3.5%) and 6.1% (std 4.2%) Word Error Rate'
      + ' (WER), respectively. We further evaluated EchoSpeech under scenarios including walking and noise'
      + ' injection to test its robustness. We then demonstrated using EchoSpeech in demo applications in'
      + ' real-time operating at 73.3mW, where the real-time pipeline was implemented on a smartphone with'
      + ' only 1-6 minutes of training data. We believe that EchoSpeech takes a solid step towards'
      + ' minimally-obtrusive wearable SSI for real-life deployment.',
  },
  {
    title: 'EchoSpeech: Continuous Silent Speech Recognition on Minimally-obtrusive Eyewear Powered by Acoustic Sensing',
    subtitle: 'April 2023 @ Hamburg, Germany',
    location: 'Hamburg, Germany',
    image: '/images/talks/echospeechtalk.jpg',
    link: '/talks/echospeechtalk',
    date: '2023-04-26',
    projects: ['EchoSpeech'],
    talk_desc:
      'Presented EchoSpeech at CHI \'23',

    year: '2023',
    abstract:
      'We present EchoSpeech, a minimally-obtrusive silent speech interface (SSI) powered by low-power'
      + ' active acoustic sensing. EchoSpeech uses speakers and microphones mounted on a glass-frame and'
      + ' emits inaudible sound waves towards the skin. By analyzing echos from multiple paths, EchoSpeech'
      + ' captures subtle skin deformations caused by silent utterances and uses them to infer silent speech.'
      + ' With a user study of 12 participants, we demonstrate that EchoSpeech can recognize 31 isolated'
      + ' commands and 3-6 figure connected digits with 4.5% (std 3.5%) and 6.1% (std 4.2%) Word Error Rate'
      + ' (WER), respectively. We further evaluated EchoSpeech under scenarios including walking and noise'
      + ' injection to test its robustness. We then demonstrated using EchoSpeech in demo applications in'
      + ' real-time operating at 73.3mW, where the real-time pipeline was implemented on a smartphone with'
      + ' only 1-6 minutes of training data. We believe that EchoSpeech takes a solid step towards'
      + ' minimally-obtrusive wearable SSI for real-life deployment.',
  },
  {
    title: 'Wireless Sensing Around the Body: Towards Tracking and Interpreting Body Postures in the Wild on Wearables',
    subtitle: 'March 2023 @ Ithaca, NY, USA',
    location: 'Ithaca, NY, USA',
    image: '/images/talks/csltalk202303.jpg',
    link: '/talks/csltalk202303',
    date: '2023-03-15',
    projects: ['EchoSpeech'],
    talk_desc:
      'Breiefly presented EchoSpeech as part of a talk by SciFi Lab',

    year: '2023',
    abstract:
      'Despite the fast growth of AI, computers have limited ability to interpret human physical behaviors in everyday life. '
      + 'For example, computers still cannot yet accurately distinguish basic daily activities such as eating and drinking. One '
      + 'key challenge is the lack of high-quality behavior data in the wild, such as body postures on the hands, face, and limbs. '
      + 'Existing body posture sensing technologies encounter at least one of the following issues when used continuously in daily '
      + 'settings: 1) limited sensing area, 2) cumbersome form factor, 3) high energy consumption, and 4) low resolution. The '
      + 'inability to continuously track high-quality fine-grained body postures in daily settings has hindered computers\' ability '
      + 'to comprehensively and continuously understand complex human behaviors and interpret their intentions.'
      + '\n'
      + 'The research in the SciFi lab aims to develop a low-power, privacy-sensitive, and intelligent sensing platform with minimally '
      + 'obtrusive wearables that can be deployed in everyday life to track and interpret a key set of fine-grained body postures. '
      + 'The key sensing principle behind these projects is that different body postures lead to unique patterns and formations of '
      + 'nearby skin or contours of the body, which can be captured by wearable wireless sensors to infer fine-grained body postures '
      + 'and activities. In this talk, we will present a series of novel sensing technologies from the SciFi lab, including a wristband '
      + 'that can track full body postures (IMWUT \'22, Hyunchul Lim), an earable that can track facial expressions (IMWUT \'22, Ke Li), and '
      + 'glasses that can continuously interpret your speech from subtle skin deformations (CHI \'23, Ruidong Zhang).',
  },
  {
    title: 'EatingTrak: Detecting Fine-grained Eating Moments in the Wild Using a Wrist-mounted IMU',
    subtitle: 'September 2022 @ Vancouver, BC, Canada',
    location: 'Vancouver, BC, Canada',
    link: '/talks/eatingtraktalk',
    image: '/images/talks/eatingtraktalk.jpg',
    date: '2022-09-29',
    projects: ['EatingTrak'],
    talk_desc:
      'Presented EatingTrak at MobileHCI \'22',

    year: '2022',
    abstract:
      'In this paper, we present EatingTrak, an AI-powered sensing system using a wrist-mounted inertial '
      + 'measurement unit (IMU) to recognize eating moments in a near-free-living semi-wild setup. It '
      + 'significantly improves the SOTA in time resolution using similar hardware on identifying eating '
      + 'moments, from over five minutes to three seconds. Different from prior work which directly learns '
      + 'from raw IMU data, it proposes intelligent algorithms which can estimate the arm posture in 3D in '
      + 'the wild and then learns the detailed eating moments from the series of estimated arm postures. '
      + 'To evaluate the system, we collected eating activity data from 9 participants in semi-wild scenarios '
      + 'for over 113 hours. Results showed that it was able to recognize eating moments at three '
      + 'time-resolutions: 3 seconds and 15 minutes with F-1 scores of 73.7% and 83.8%, respectively. '
      + 'EatingTrak would introduce new opportunities in sensing detailed eating behavior information '
      + 'requiring high time resolution, such as eating frequency, snack-taking, on-site behavior '
      + 'intervention. We also discuss the opportunities and challenges in deploying EatingTrak on '
      + 'commodity devices at scale.',
  },
  {
    title: 'SpeeChin: A Smart Necklace for Silent Speech Recognition',
    subtitle: 'September 2022 @ Atlanta, GA, USA',
    location: 'Atlanta, GA, USA',
    link: '/talks/speechintalk',
    image: '/images/talks/speechintalk.jpg',
    date: '2022-09-13',
    projects: ['SpeeChin'],
    talk_desc:
      'Presented SpeeChin at Ubicomp \'22',

    year: '2022',
    abstract:
      'This paper presents SpeeChin, a smart necklace that can recognize 54 English and 44 Chinese silent speech '
      + 'commands. A customized infrared (IR) imaging system is mounted on a necklace to capture images of the neck '
      + 'and face from under the chin. These images are first pre-processed and then deep learned by an end-to-end '
      + 'deep convolutional-recurrent-neural-network (CRNN) model to infer different silent speech commands. A user '
      + 'study with 20 participants (10 participants for each language) showed that SpeeChin could recognize 54 English '
      + 'and 44 Chinese silent speech commands with average cross-session accuracies of 90.5% and 91.6%, respectively. '
      + 'To further investigate the potential of SpeeChin in recognizing other silent speech commands, we conducted '
      + 'another study with 10 participants distinguishing between 72 one-syllable nonwords. Based on the results from '
      + 'the user studies, we further discuss the challenges and opportunities of deploying SpeeChin in real-world applications.',
  },
];

export default talksData;
