import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faAngellist } from '@fortawesome/free-brands-svg-icons/faAngellist';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faLocationDot, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
// import { faLocationDot } from '@fortawesome/react-fontawesome'
// See https://fontawesome.com/icons?d=gallery&s=brands,regular&m=free
// to add other icons.

const contactsData = [
  // {
  //   link: 'https://github.com/mldangelo',
  //   label: 'Github',
  //   icon: faGithub,
  // },
  // {
  //   link: 'https://facebook.com/md',
  //   label: 'Facebook',
  //   icon: faFacebookF,
  // },
  // {
  //   link: 'https://www.instagram.com/dangelosaurus/',
  //   label: 'Instagram',
  //   icon: faInstagram,
  // },
  // {
  //   link: 'https://angel.co/michael-d-angelo',
  //   label: 'Angel List',
  //   icon: faAngellist,
  // },
  // {
  //   link: 'https://twitter.com/dangelosaurus',
  //   label: 'Twitter',
  //   icon: faTwitter,
  // },
  {
    link: 'https://goo.gl/maps/ssPi3NMvFWPm27rS7',
    label: 'Ithaca, NY, USA',
    icon: faLocationDot,
  },
  {
    link: 'https://dl.acm.org/profile/99659765664',
    label: 'ACM Profile',
    icon: 'ai ai-acm',
  },
  {
    link: 'https://scholar.google.com/citations?hl=en&user=h1PhB-sAAAAJ',
    label: 'Google Scholar',
    icon: 'ai ai-google-scholar',
  },
  {
    link: 'https://orcid.org/0000-0001-8329-0522',
    label: 'ORCID',
    icon: 'ai ai-orcid',
  },
  {
    link: 'https://www.linkedin.com/in/ruidong-zhang',
    label: 'LinkedIn',
    icon: faLinkedinIn,
  },
  {
    // link: 'mailto:rz379@cornell.edu',
    label: 'rz379 [AT] cornell [DOT] edu',
    icon: faEnvelope,
  },
];

export default contactsData;
